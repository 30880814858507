import classNames from "classnames";
import { ComponentProps, FC } from "react";
import styles from "./GridColumn.module.scss";
interface GridColumnProps extends ComponentProps<"div"> {
	columnWidth?: number | "auto";
}
export const GridColumn: FC<GridColumnProps> = ({ className, children, columnWidth, ...rest }) => {
	const _className = classNames(className, styles.gridColumn);
	return (
		<div className={_className} {...rest}>
			{children}
		</div>
	);
};
