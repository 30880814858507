import { FC, PropsWithChildren } from "react";
import styles from "./Window.module.scss";
export const Window: FC<PropsWithChildren> = () => {
	return (
		<div className={styles.window}>
			<header>
				<div>
					<span className={styles.dot} />
					<span className={styles.dot} />
					<span className={styles.dot} />
				</div>
			</header>
			Window
		</div>
	);
};
