import { createContext, FC, PropsWithChildren, useContext, useEffect, useId, useState } from "react";
import { useHotspotContext } from "./HotspotProvider";

interface HotspotItemContextProps {
	id?: string;
	isActive?: boolean;
	setIsActive?: (index: boolean) => void;
}

export const HotspotItemContext = createContext<HotspotItemContextProps>({});
export const HotspotItemContextProvider: FC<PropsWithChildren> = ({ children }) => {
	const { idsActiveItems } = useHotspotContext();
	const [isActive, setIsActive] = useState<boolean>(false);
	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	const [id, setId] = useState<string>(useId());
	useEffect(() => {
		if (idsActiveItems.includes(id)) {
			setIsActive(true);
		} else {
			setIsActive(false);
		}
	}, [idsActiveItems]);

	return <HotspotItemContext.Provider value={{ id, isActive, setIsActive }}>{children}</HotspotItemContext.Provider>;
};
export const useHotspotItemContext = () => {
	return useContext<HotspotItemContextProps>(HotspotItemContext);
};
