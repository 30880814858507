import Image from "next/legacy/image";
import { FC } from "react";
interface HotspotImageProps {
	imageSrc: string;
	imageAlt?: string;
	width: number;
	height: number;
}
export const HotspotImage: FC<HotspotImageProps> = ({ imageSrc, height, width, imageAlt }) => {
	return (
		<div>
			<Image src={imageSrc} alt={imageAlt ?? ""} width={width} height={height} layout={"responsive"} />
		</div>
	);
};
