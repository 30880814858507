import classNames from "classnames";
import Image from "next/legacy/image";
import Link from "next/link";
import { FC } from "react";
import { IconSelection } from "../Icon";
import styles from "./Card.module.scss";
interface CardProps {
	title?: string;
	subTitle?: string;
	href: string;
	headerLabel?: string;
	imageSrc?: string;
	imageAlt?: string;
	imagePortrait?: boolean;
	icon?: IconSelection;
	contentAboveImage?: boolean;
	theme?: "default" | "blue";
}
export const Card: FC<CardProps> = ({
	headerLabel,
	icon,
	contentAboveImage = false,
	imagePortrait = false,
	href,
	subTitle,
	title,
	imageAlt,
	imageSrc,
}) => {
	const imageWidth = imagePortrait ? 300 : 1600;
	const imageHeight = imagePortrait ? 400 : 900;
	const contentClass = classNames(styles.content, {
		[styles.contentAboveImage]: contentAboveImage,
	});
	return (
		<Link href={href} passHref={true} className={styles.card}>
			{imageSrc && (
				<Image
					className={styles.image}
					src={imageSrc}
					alt={imageAlt}
					width={imageWidth}
					height={imageHeight}
					objectFit={"cover"}
					layout={"responsive"}
				/>
			)}

			<div className={contentClass}>
				{headerLabel && <h4 className={styles.headerLabel}>{headerLabel.toUpperCase()}</h4>}
				{title && <h3 className={styles.title}>{title}</h3>}
				{subTitle && <h4 className={styles.subTitle}>{subTitle}</h4>}
			</div>
		</Link>
	);
};
