import { FC, PropsWithChildren } from "react";
import { HotspotContextProvider } from "../../providers/HotspotProvider";
import styles from "./Hotspot.module.scss";
interface HotspotProps {
	multiple?: boolean;
}
export const Hotspot: FC<PropsWithChildren<HotspotProps>> = ({ children, multiple = false }) => {
	return (
		<HotspotContextProvider multiple={multiple}>
			<div className={styles.hotspot}>{children}</div>
		</HotspotContextProvider>
	);
};
