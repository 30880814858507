import { FC } from "react";
import { Button } from "../Button";

interface SnippetProps {
	text: string;
}

export const Snippet: FC<SnippetProps> = ({ text }) => {
	const copyToClipBoard = async (value: string) => {
		await navigator.clipboard.writeText(value);
	};
	return (
		<div>
			<pre>$ {text}</pre>
			<Button iconOnly={true} icon={"copy"} variant={"blank"} onClick={() => copyToClipBoard(text)} />
		</div>
	);
};
