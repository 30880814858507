import { createContext, FC, PropsWithChildren, useContext, useState } from "react";
interface HotspotContextProps {
	idsActiveItems?: string[];
	changeActiveItem?: (index: string) => void;
}
interface HotspotContextProviderProps {
	multiple?: boolean;
	onChange?: () => string[];
}

export const HotspotContext = createContext<HotspotContextProps>({});
export const HotspotContextProvider: FC<PropsWithChildren<HotspotContextProviderProps>> = ({
	children,
	multiple,
	onChange,
}) => {
	const [idsActiveItems, setIdsActiveItems] = useState<string[]>([]);
	const changeActiveItem = (index: string): void => {
		let _idsActiveItems = [];
		if (idsActiveItems.includes(index)) {
			_idsActiveItems = idsActiveItems.filter((value) => {
				return value !== index;
			});
		}
		if (!idsActiveItems.includes(index) && !multiple) {
			_idsActiveItems = [index];
		}
		if (!idsActiveItems.includes(index) && multiple) {
			_idsActiveItems = [...idsActiveItems, index];
		}
		if (onChange) {
			onChange();
		}
		setIdsActiveItems(_idsActiveItems);
	};
	return <HotspotContext.Provider value={{ idsActiveItems, changeActiveItem }}>{children}</HotspotContext.Provider>;
};
export const useHotspotContext = (): HotspotContextProps => {
	return useContext<HotspotContextProps>(HotspotContext);
};
