import { ComponentProps, FC } from "react";

interface HeadlineProps extends ComponentProps<"h1"> {
	level?: 1 | 2 | 3 | 4 | 5 | 6;
	size?: "xxl" | "xl" | "l";
}
type HeadingTag = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

export const Headline: FC<HeadlineProps> = ({ children, level = 3, size = "xl", ...rest }) => {
	const Level = ("h" + level) as HeadingTag;
	return <Level {...rest}>{children}</Level>;
};
