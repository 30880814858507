import Link from "next/link";
import { FC } from "react";
import { useLayoutContext } from "../../providers";
import { Button } from "../Button";
import styles from "./Navigation.module.scss";
const NAVIGATION_ITEMS = [
	{ title: "Blog", href: "/blog" },
	{ title: "Snippets", href: "/snippets" },
	{ title: "Tools", href: "/tools" },
	{ title: "Concepts", href: "/concepts" },
];

export const Navigation: FC = () => {
	const { mobileMenuOpen, toggleMobileMenu } = useLayoutContext();
	return (
		<>
			<nav role={"navigation"} className={styles.nav}>
				<ul className={styles.navList}>
					{NAVIGATION_ITEMS.map(({ title, href }, index) => {
						return (
							<li className={styles.navItem} key={index}>
								<Link href={href} passHref={true} className={styles.link}>
									{title}
								</Link>
							</li>
						);
					})}
				</ul>
			</nav>
			<Button
				variant={"blank"}
				className={styles.menuButton}
				noSpacing={true}
				data-show-nav={mobileMenuOpen}
				onClick={() => toggleMobileMenu(!mobileMenuOpen)}
				circle={true}
				aria-label={"menu"}
			/>

			{mobileMenuOpen && (
				<aside className={styles.mobileNav}>
					<nav role={"navigation"}>
						<ul className={styles.navList} data-mobile={true}>
							{NAVIGATION_ITEMS.map(({ title, href }, index) => {
								return (
									<li className={styles.navItem} key={index}>
										<Link href={href} passHref={true} className={styles.link}>
											{title}
										</Link>
									</li>
								);
							})}
						</ul>
					</nav>
				</aside>
			)}
		</>
	);
};
