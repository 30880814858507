import classNames from "classnames";
import { ComponentProps, forwardRef, HTMLInputTypeAttribute } from "react";
import styles from "./Input.module.scss";

type InputProps = ComponentProps<"input"> & {
	type: HTMLInputTypeAttribute | "switch" | undefined;
	label?: string;
};
const TEXT_INPUT_TYPES = ["text", "password", "email", "number", "url", "search", "tel"];
export const Input = forwardRef<HTMLInputElement, InputProps>(({ type = "text", label, className, ...rest }, ref) => {
	let _classNames = classNames(className, {
		[styles.input]: TEXT_INPUT_TYPES.includes(type),
	});
	let input= <input type={type} {...rest} className={_classNames} ref={ref} />;
	if(label){
		input=(<label>
			{label}
			{input}
		</label>)
	}
	return input
});
