import {
	autoUpdate,
	flip,
	offset,
	shift,
	useClick,
	useDismiss,
	useFloating,
	useInteractions,
	useRole,
} from "@floating-ui/react";
import { FC, useState } from "react";
import _styles from "./HotspotDot.module.scss";

import { useHotspotItemContext } from "../../providers/HotspotItemProvider";
import { useHotspotContext } from "../../providers/HotspotProvider";

interface HotspotDotProps {
	top: number;
	left: number;
}

export const HotspotDot: FC<HotspotDotProps> = ({ top, left }) => {
	const { id, isActive } = useHotspotItemContext();
	const { changeActiveItem } = useHotspotContext();
	const [open, setOpen] = useState(false);

	const { x, y, strategy, refs, context } = useFloating({
		open,
		onOpenChange: setOpen,
		middleware: [offset(10), flip(), shift()],
		whileElementsMounted: autoUpdate,
	});
	const click = useClick(context);
	const dismiss = useDismiss(context);
	const role = useRole(context, { role: "tooltip" });

	// Merge all the interactions into prop getters
	const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);
	return (
		<>
			<button
				type="button"
				className={_styles.dot}
				style={{ top: top + "%", left: left + "%" }}
				ref={refs.setReference}
				onClick={() => changeActiveItem(id)}
				{...getReferenceProps()}></button>
			{open && (
				<div
					ref={refs.setFloating}
					style={{
						position: strategy,
						top: y ?? 0,
						left: x ?? 0,
						width: "max-content",
					}}
					{...getFloatingProps()}>
					Tooltip element
				</div>
			)}
		</>
	);
};
