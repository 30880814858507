import classNames from "classnames";
import { FC } from "react";
import { useThemeContext } from "../../providers";
import styles from "./Logo.module.scss";
interface LogoProps {
	size?: "sm" | "lg" | null;
}
export const Logo: FC<LogoProps> = ({ size }) => {
	const { theme } = useThemeContext();
	let _classNames = classNames(styles.logo, {
		[styles.sm]: size === "sm",
		[styles.lg]: size === "lg",
	});
	return (
		<div className={_classNames}>
			<svg version="1.1" viewBox="0 0 262 274" xmlns="http://www.w3.org/2000/svg">
				<path
					d="m208.9 28.686c-23.181-17.981-52.289-28.686-83.898-28.686h-125v213h24.956l30-30h-24.956v-153h95c23.316 0 44.89 7.458 62.467 20.118z"
					fill={theme === "dark" ? "#fff" : "#000"}
				/>
				<path
					d="m209.75 71.675c13.95 18.072 22.249 40.729 22.249 65.325 0 59.094-47.906 107-107 107h-29v-31h28.499c41.973 0 76-34.026 76-76s-34.027-76-76-76h-58.499v110.96l30-30v-50.956h28.499c25.405 0 46 20.595 46 46s-20.595 46-46 46h-25.702l-32.797 32.797v58.203h59c75.663 0 137-61.337 137-137 0-32.884-11.586-63.063-30.899-86.676z"
					fill={theme === "dark" ? "#fff" : "#000"}
				/>
			</svg>
		</div>
	);
};
