import { ComponentProps, FC } from "react";
import {
	RiAlertFill,
	RiArrowDownSLine,
	RiArrowLeftSLine,
	RiArrowRightSLine,
	RiArrowUpSLine,
	RiCheckLine,
	RiCloseLine,
	RiCopyrightFill,
	RiErrorWarningFill,
	RiFacebookFill,
	RiHeartLine,
	RiHome2Line,
	RiInformationFill,
	RiLink,
	RiMenuLine,
	RiMoonFill,
	RiSearchLine,
	RiShoppingBagLine,
	RiStopFill,
	RiSunFill,
	RiTwitterFill,
	RiUserLine,
} from "react-icons/ri";
import { SiGithub, SiInstagram, SiJavascript, SiSass, SiTypescript, SiXing } from "react-icons/si";

export interface IconProps extends ComponentProps<"svg"> {
	icon: IconSelection;
}

export const icons = [
	"search",
	"arrowDown",
	"arrowUp",
	"arrowLeft",
	"arrowRight",
	"xing",
	"copy",
	"instagram",
	"sass",
	"close",
	"account",
	"menu",
	"cart",
	"heart",
	"check",
	"link",
	"sun",
	"moon",
	"twitter",
	"facebook",
	"home",
	"info",
	"warning",
	"typescript",
	"javascript",
	"github",
	"alert",
];
export type IconSelection = (typeof icons)[number];

export const Icon: FC<IconProps> = ({ icon, ...props }) => {
	switch (icon) {
		case "account":
			return <RiUserLine {...props} />;
		case "search":
			return <RiSearchLine {...props} />;
		case "menu":
			return <RiMenuLine {...props} />;
		case "cart":
			return <RiShoppingBagLine {...props} />;
		case "home":
			return <RiHome2Line {...props} />;
		case "heart":
			return <RiHeartLine {...props} />;
		case "arrowDown":
			return <RiArrowDownSLine {...props} />;
		case "arrowUp":
			return <RiArrowUpSLine {...props} />;
		case "arrowLeft":
			return <RiArrowLeftSLine {...props} />;
		case "xing":
			return <SiXing {...props} />;
		case "arrowRight":
			return <RiArrowRightSLine {...props} />;
		case "link":
			return <RiLink {...props} />;
		case "close":
			return <RiCloseLine {...props} />;
		case "facebook":
			return <RiFacebookFill {...props} />;
		case "check":
			return <RiCheckLine {...props} />;
		case "twitter":
			return <RiTwitterFill {...props} />;
		case "sun":
			return <RiSunFill {...props} />;
		case "moon":
			return <RiMoonFill {...props} />;
		case "info":
			return <RiInformationFill {...props} />;
		case "alert":
			return <RiAlertFill {...props} />;
		case "warning":
			return <RiErrorWarningFill {...props} />;
		case "typescript":
			return <SiTypescript {...props} />;
		case "github":
			return <SiGithub {...props} />;
		case "instagram":
			return <SiInstagram {...props} />;
		case "javascript":
			return <SiJavascript {...props} />;
		case "sass":
			return <SiSass {...props} />;
		case "copy":
			return <RiCopyrightFill {...props} />;
		default:
			return <RiStopFill {...props} />;
	}
};
